import { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import SubMenu from "./SubMenu";
import Icon from "./Icon";
import RemoveIcon from "./RemoveIcon";
import AddIcon from "./AddIcon";
type SideLinkProps = {
  onClick: () => void;
  text: string;
  icon: string;
  href: string;
  isActive: boolean;
  toggleIcon?: string;
  subMenu?: string;
  isNew: boolean;
};
const SideLink = ({
  onClick,
  text,
  icon,
  href,
  isActive,
  toggleIcon,
  isNew,
}: SideLinkProps) => {
  const location = useLocation();
  const [toggleSubmenu, setToggleSubmenu] = useState(false);
  function handleToggleSubmenu() {
    setToggleSubmenu(!toggleSubmenu);
    // event.cancelBubble = true;
  }
  return (
    <NavLink className="w-[90%] my-1 group" to={href}>
      <li
        onClick={onClick}
        className={`w-full flex flex-wrap justify-start items-center py-3 group transition-all hover:rounded-tl-[100px]  hover:rounded-bl-[100px]   ${
          isActive
            ? "bg-white rounded-tl-[100px] rounded-bl-[100px] text-[#102448] ml-2"
            : "text-white hover:bg-[#00000044]"
        }`}
      >
        <div className="w-40 flex  justify-center lg:gap-0 lg:justify-evenly text-base">
          <div className="w-28 flex items-center text-sm lg:text-base">
            {/* <div className="flex items-center lg:mr-2 w-6 scale-75 lg:scale-100">
              {icon}
            </div> */}
            <Icon
              iconUrl={`/icons/SideLink/${
                !isActive ? icon : icon + "_bold"
              }.svg`}
              width={25}
              height={25}
              customeClass="mr-3"
            />
            {text}
            {isNew && (
              <span className="rounded-full text-prima-red px-2 font-bold bg-white text-[10px] leading-4 ml-2 mt-0.5">
                New
              </span>
            )}
            <span className="flex lg:hidden">
              {toggleIcon && (
                <>
                  <span
                    className="flex rounded  hover:bg-[#00000084] scale-50 lg:scale-75 "
                    onClick={handleToggleSubmenu}
                  >
                    {toggleSubmenu ? (
                      <RemoveIcon size={25} white={!isActive} />
                    ) : (
                      <AddIcon size={25} white={!isActive} />
                    )}
                  </span>
                </>
              )}
            </span>
          </div>
        </div>
        <span className="hidden lg:flex">
          {toggleIcon && (
            <>
              <span
                className="flex rounded  hover:bg-[#00000084] scale-50 lg:scale-75"
                onClick={handleToggleSubmenu}
              >
                {toggleSubmenu ? (
                  <RemoveIcon size={25} white={!isActive} />
                ) : (
                  <AddIcon size={25} white={!isActive} />
                )}
              </span>
            </>
          )}
        </span>
      </li>

      {toggleSubmenu && text === "Properties" && (
        <>
          <SubMenu
            isClicked={location.pathname === "/properties/"}
            href="/properties/"
            subMenu="All"
          />
          <SubMenu
            isClicked={location.pathname === "/properties/my_properties"}
            href="/properties/my_properties"
            subMenu="My Properties"
          />
          {/* <SubMenu
            isClicked={location.pathname.startsWith("/properties/for_sale")}
            href="/properties/for_sale"
            subMenu="For Sale"
          />
          <SubMenu
            isClicked={location.pathname.startsWith("/properties/for_rent")}
            href="/properties/for_rent"
            subMenu="For Rent"
          /> */}
          {/* <SubMenu
            isClicked={location.pathname.startsWith("/properties/sold")}
            href="/properties/sold"
            subMenu="Sold"
          />
          <SubMenu
            isClicked={location.pathname.startsWith("/properties/rented")}
            href="/properties/rented"
            subMenu="Rented"
          /> */}
          <SubMenu
            isClicked={location.pathname.startsWith("/properties/archived")}
            href="/properties/archived"
            subMenu="Archived"
          />
          {/* <SubMenu
            className="text-xs"
            isClicked={location.pathname.startsWith(
              "/properties/externalproperty"
            )}
            href="/properties/externalproperty"
            subMenu="External Property"
          /> */}
        </>
      )}
      {toggleSubmenu && text === "Enquiries" && (
        <>
          <SubMenu
            isClicked={location.pathname.startsWith("/enquiries/valid")}
            href="/enquiries/valid"
            subMenu="Valid"
          />
          <SubMenu
            isClicked={location.pathname.startsWith("/enquiries/invalid")}
            href="/enquiries/invalid"
            subMenu="Invalid"
          />
          <SubMenu
            isClicked={location.pathname.startsWith("/enquiries/nonpersonal")}
            href="/enquiries/nonpersonal"
            subMenu="Non Personal"
          />
        </>
      )}
      {toggleSubmenu && text === "Customers" && (
        <>
          <SubMenu
            isClicked={location.pathname === "/customers/individual"}
            href="/customers/individual"
            subMenu="Individuals"
          />
          <SubMenu
            isClicked={location.pathname === "/companies/"}
            href="/companies/"
            subMenu="Companies"
          />
        </>
      )}
      {toggleSubmenu && text === "Deals" && (
        <>
          <SubMenu
            isClicked={location.pathname.startsWith("/deals/my_deals")}
            href="/deals/my_deals"
            subMenu="My Deals"
          />
          <SubMenu
            isClicked={location.pathname.startsWith("/deals/shared_with_me")}
            href="/deals/shared_with_me"
            subMenu="Shared with me"
          />
        </>
      )}
      {toggleSubmenu && text === "Reports" && (
        <>
          <SubMenu
            isClicked={location.pathname.startsWith("/reports/useractivity")}
            href="/reports/useractivity"
            subMenu="User Activity"
          />
        </>
      )}
    </NavLink>
  );
};

export default SideLink;
