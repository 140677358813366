type CardCountUndreadProp = {
  i: number;
};
const CardCountUndread = ({ i }: CardCountUndreadProp) => {
  return (
    <span className="w-5 h-5 p-1.5 rounded-full bg-red-600 absolute -top-2 -right-2 flex text-white justify-center items-center text-xs">
      {i >= 9 ? "+9" : i + 1}
    </span>
  );
};

export default CardCountUndread;
