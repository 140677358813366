import { createSlice } from "@reduxjs/toolkit";
import { PropertytypeType } from "../validation/PropertytypeType";

const initialPropertytype = {
  title: "",
  property_usage: [],
};
export interface PropertytypeState {
  validationErrors: Partial<PropertytypeType>;
  propertytype: PropertytypeType;
}
const initialState: PropertytypeState = {
  validationErrors: {},
  propertytype: initialPropertytype,
};
export const propertytypeSlice = createSlice({
  name: "propertytype",
  initialState,
  reducers: {
    setPropertytype: (state, action) => {
      state.propertytype = { ...state.propertytype, ...action.payload };
    },
    clearPropertytype: (state) => {
      state.propertytype = initialPropertytype;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
  },
});
export const {
  setPropertytype,
  clearPropertytype,
  setValidationErrors,
  resetValidationErrors,
} = propertytypeSlice.actions;
export default propertytypeSlice.reducer;
