import { createSlice } from "@reduxjs/toolkit";
import { FileObject } from "../../types/fileType";
import { OwnerWizardType } from "../../validation/wizard/owner";

const initialOwnerWizard = {
  customer_name: "",
  customer_surName: "",
  phone_number: "",
  email: "",
  passport_number: "",
  nationality: null,
  birthplace: "",
  uae_resident: false,
  phone_dial_id: 784,
  alternate_phone_number: "",
  owner_already_added: true,
  is_toggle: false,
};
export interface OwnerWizardState {
  validationErrors: Partial<OwnerWizardType>;
  ownerwizard: Partial<OwnerWizardType> & {
    emirates_id_proof?: FileObject;
    passport_proof?: FileObject;
    vat_certificate?: FileObject;
    visa_proof?: FileObject;
  };
}

const initialState: OwnerWizardState = {
  validationErrors: {},
  ownerwizard: initialOwnerWizard,
};
export const ownerwizardSlice = createSlice({
  name: "ownerwizard",
  initialState,
  reducers: {
    setOwnerWizard: (state, action) => {
      const newState = { ...state.ownerwizard, ...action.payload };

      state.ownerwizard = newState;
    },
    clearOwnerWizard: (state) => {
      state.ownerwizard = initialOwnerWizard;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
  },
});
export const {
  setOwnerWizard,
  setValidationErrors,
  clearOwnerWizard,
  resetValidationErrors,
} = ownerwizardSlice.actions;
export default ownerwizardSlice.reducer;
