import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface Note {
  id?: string;
  text: string;
  createdAt?: string;
  updatedAt?: string;
  note_creator?: { name: string; surname: string };
  is_pin?: boolean;
  customerId?: string;
  notification_time_type?: [];
}

export interface NoteState {
  notes: Note[];
  newNote?: Note;
  selectedCustomer: string;
}

const initialState: NoteState = {
  notes: [],
  newNote: {
    id: "",
    text: "",
    createdAt: "",
    updatedAt: "",
    note_creator: { name: "", surname: "" },
    is_pin: false,
    customerId: "",
  },
  selectedCustomer: "",
};

export const noteSlice = createSlice({
  name: "note",
  initialState,
  reducers: {
    addNote: (state, action: PayloadAction<Note>) => {
      state.notes.push(action.payload);
    },
    updateNote: (
      state,
      action: PayloadAction<{ id: string; content: string }>
    ) => {
      const note = state.notes.find((n) => n.id === action.payload.id);
      if (note) {
        note.text = action.payload.content;
        note.updatedAt = new Date().toISOString();
      }
    },
    setNote: (state, action: PayloadAction<Partial<NoteState>>) => {
      return { ...state, ...action.payload };
    },
    clearNewNote: (state) => {
      state.newNote = undefined;
    },
    clearNote: (state) => {
      state.notes = [];
      state.newNote = undefined;
    },
  },
});

export const { addNote, updateNote, setNote, clearNote, clearNewNote } =
  noteSlice.actions;
export default noteSlice.reducer;
