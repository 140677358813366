export function hasPermission(
  permissions: {
    action: string;
    subject: string;
  }[],
  action: string,
  subject: string
) {
  return (
    permissions.findIndex(
      (item) => item.action === action && item.subject === subject
    ) !== -1
  );
}
