import { createSlice } from "@reduxjs/toolkit";
import { AreaType } from "../validation/areaType";

const initialArea = {
  title: "",
};
export interface AreaState {
  validationErrors: object;
  area: AreaType;
}
const initialState: AreaState = {
  validationErrors: {},
  area: initialArea,
};
export const areaSlice = createSlice({
  name: "area",
  initialState,
  reducers: {
    setArea: (state, action) => {
      state.area = { ...state.area, ...action.payload };
    },
    clearArea: (state) => {
      state.area = initialArea;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
  },
});
export const {
  setArea,
  clearArea,
  setValidationErrors,
  resetValidationErrors,
} = areaSlice.actions;
export default areaSlice.reducer;
