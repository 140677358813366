import { configureStore } from "@reduxjs/toolkit";
import uiReducer from "../store/ui/ui-slice";
import propertiesReducer from "../store/properties/propertiesSlice";
import enquiryReducer from "../store/enquirySlice";
import customerReducer from "../store/customerSlice";
import companyReducer from "../store/companySlice";
import userReducer from "../store/userSlice";
import loginSlice from "../store/loginSlice";
import { listenerMiddleware } from "../store/middleware/listener";
import roleSlice from "../store/roleSlice";
import leadReducer from "../store/leadSlice";
import areaSlice from "../store/areaSlice";
import featureSlice from "../store/featureSlice";
import sourceSlice from "../store/sourceSlice";
import propertytypeSlice from "../store/property-type-slice";
import sitevisitSlice from "../store/site-visit-slice";
import followupSlice from "../store/follow-up-slice";
import dealSlice from "../store/deal-slice";
import externalagentSlice from "../store/external-agent-slice";
import externalpropertySlice from "../store/external-property-slice";
import useractivityReducer from "../store/user-activity-slice";
import rentandsalecardSlice from "../store/rent-sale-card";
import chartpropertySlice from "../store/chart-property";
import notificationSlice from "../store/notification";
import propertyviewSlice from "../store/property-view";
import commentSlice from "../store/comment-slice";
import attachmentSlice from "../store/attachment-slice";
import ownerwizardSlice from "../store/wizard/owner-slice";
import locationwizardSlice from "../store/wizard/location-slice";
import propertywizardSlice from "../store/wizard/property-wizard-slice";
import amenitieswizardSlice from "../store/wizard/amenities-slice";
import listingtypewizardSlice from "../store/wizard/listing-type-slice";
import preownerwizardSlice from "../store/wizard/pre-owner-slice";
import stepSlice from "../store/stepSlice";
import viewSlice from "../store/viewSlice";
import NoteSlice from "../store/NoteSlice";
export const store = configureStore({
  reducer: {
    ui: uiReducer,
    properties: propertiesReducer,
    enquiry: enquiryReducer,
    customer: customerReducer,
    user: userReducer,
    company: companyReducer,
    login: loginSlice,
    role: roleSlice,
    lead: leadReducer,
    area: areaSlice,
    feature: featureSlice,
    source: sourceSlice,
    propertytype: propertytypeSlice,
    propertyview: propertyviewSlice,
    sitevisit: sitevisitSlice,
    followup: followupSlice,
    deal: dealSlice,
    externalagent: externalagentSlice,
    externalproperty: externalpropertySlice,
    useractivity: useractivityReducer,
    rentandsalecard: rentandsalecardSlice,
    chartproperty: chartpropertySlice,
    notification: notificationSlice,
    comment: commentSlice,
    attachment: attachmentSlice,
    preownerwizard: preownerwizardSlice,
    ownerwizard: ownerwizardSlice,
    locationwizard: locationwizardSlice,
    propertywizard: propertywizardSlice,
    amenitieswizard: amenitieswizardSlice,
    listingtypewizard: listingtypewizardSlice,
    step: stepSlice,
    view: viewSlice,
    note: NoteSlice
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(listenerMiddleware.middleware),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
