import { NavLink } from "react-router-dom";

type SubMenuProps = {
  subMenu: string;
  href: string;
  isClicked: boolean;
  className?: string;
};
const SubMenu = ({ subMenu, href, isClicked, className }: SubMenuProps) => {
  return (
    <NavLink to={href}>
      <ul className="flex flex-col items-end">
        <li
          className={`w-[80%] flex flex-wrap justify-start items-center py-2 mt-1 group transition-all hover:rounded-tl-[100px]  hover:rounded-bl-[100px]   ${
            isClicked
              ? "bg-[#00000044] rounded-tl-[100px] rounded-bl-[100px] text-white ml-2"
              : "text-white hover:bg-[#00000044]"
          }`}
        >
          <div className="w-40 flex justify-evenly text-sm lg:text-base ">
            <div className={`w-28 flex items-center ${className}`}>
              {subMenu}
            </div>
          </div>
        </li>
      </ul>
    </NavLink>
  );
};

export default SubMenu;
