import { createSlice } from "@reduxjs/toolkit";
import { AmenitiesWizardType } from "../../validation/wizard/amenities";
const initialAmenitiesWizard = {
  featur_Ids: [],
};
export interface AmenitiesWizardState {
  validationErrors: Partial<AmenitiesWizardType>;
  amenitieswizard: Partial<AmenitiesWizardType>;
}

const initialState: AmenitiesWizardState = {
  validationErrors: {},
  amenitieswizard: initialAmenitiesWizard,
};
export const amenitieswizardSlice = createSlice({
  name: "amenitieswizard",
  initialState,
  reducers: {
    setAmenitiesWizard: (state, action) => {
      const newState = { ...state.amenitieswizard, ...action.payload };
      state.amenitieswizard = newState;
    },
    clearAmenitiesWizard: (state) => {
      state.amenitieswizard = initialAmenitiesWizard;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
  },
});
export const {
  setAmenitiesWizard,
  setValidationErrors,
  clearAmenitiesWizard,
  resetValidationErrors,
} = amenitieswizardSlice.actions;
export default amenitieswizardSlice.reducer;
