import { createSlice } from "@reduxjs/toolkit";
import { SiteVisitType } from "../validation/site-visit-type";

const initialSiteVisit = {
  comment: "",
  property_ids: [],
  properties: [],
  search: "",
  is_vacant: true,
};
export interface SiteVisitState {
  validationErrors: Partial<SiteVisitType>;
  editSitevisit: Partial<SiteVisitType>;
  sitevisit: Partial<SiteVisitType> & {
    search?: string;
    properties: {
      property_id: string;
      property_title: string;
      property_property_id: string;
      owner: string;
    }[];
  };
}
const initialState: SiteVisitState = {
  validationErrors: {},
  sitevisit: initialSiteVisit,
  editSitevisit: initialSiteVisit,
};
export const sitevisitSlice = createSlice({
  name: "sitevisit",
  initialState,
  reducers: {
    setSiteVisit: (state, action) => {
      state.sitevisit = { ...state.sitevisit, ...action.payload };
    },
    setSiteVisitEdited: (state, action) => {
      state.editSitevisit = { ...state.editSitevisit, ...action.payload };
    },
    clearSiteVisit: (state) => {
      state.sitevisit = initialSiteVisit;
    },
    clearEditedSiteVisit: (state) => {
      state.editSitevisit = initialSiteVisit;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
  },
});
export const {
  setSiteVisit,
  setSiteVisitEdited,
  clearSiteVisit,
  clearEditedSiteVisit,
  setValidationErrors,
  resetValidationErrors,
} = sitevisitSlice.actions;
export default sitevisitSlice.reducer;
