import { createSlice } from "@reduxjs/toolkit";

const initialChartProperty = {};
export interface ChartPropertyState {
  filter: {
    listingYear: Date | null;
    enquiryYear: Date | null;
    customerCompanyYear: Date | null;
    sourceofEnquiryYear: Date | null;
    rentVolumeYear: Date | null;
  };
  validationErrors: object;
  chartproperty: object;
}
const initialFilter = {
  listingYear: new Date(),
  enquiryYear: new Date(),
  customerCompanyYear: new Date(),
  sourceofEnquiryYear: new Date(),
  rentVolumeYear: new Date(),
};
const initialState: ChartPropertyState = {
  filter: initialFilter,
  validationErrors: {},
  chartproperty: initialChartProperty,
};
export const chartpropertySlice = createSlice({
  name: " chartproperty",
  initialState,
  reducers: {
    setRentVolumeYear: (state, action) => {
      state.filter.rentVolumeYear = action.payload;
    },
    setListingYear: (state, action) => {
      state.filter.listingYear = action.payload;
    },
    setCustomerCompanyYear: (state, action) => {
      state.filter.customerCompanyYear = action.payload;
    },
    setEnquiryYear: (state, action) => {
      state.filter.enquiryYear = action.payload;
    },
    setSourceEnquiryYear: (state, action) => {
      state.filter.sourceofEnquiryYear = action.payload;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
    resetFilter: (state) => {
      state.filter = initialFilter;
    },
  },
});
export const {
  setValidationErrors,
  resetValidationErrors,
  setRentVolumeYear,
  setListingYear,
  setCustomerCompanyYear,
  setEnquiryYear,
  setSourceEnquiryYear,
  resetFilter,
} = chartpropertySlice.actions;
export default chartpropertySlice.reducer;
