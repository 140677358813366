import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";
import Layout from "./Layout";
import Home from "./components/Home";

const Properties = lazy(() => import("./components/properties/Properties"));
const Deals = lazy(() => import("./components/deals/Deals"));
const AddDeals = lazy(() => import("./components/deals/deal/AddDeal"));

const AddProperty = lazy(
  () => import("./components/properties/Add/AddProperty")
);
const ExternalProperty = lazy(
  () => import("./components/properties/external-property/ExternalProperty")
);
const Enquiries = lazy(() => import("./components/enquiry/Enquiries"));
const AddEnquiry = lazy(() => import("./components/enquiry/Add/AddEnquiry"));
const BrochureDesign = lazy(
  () => import("./components/properties/Add/Tabs/brochure/BrochureDesign")
);
const Company = lazy(() => import("./components/company/Company"));
const AddCompany = lazy(() => import("./components/company/Add/AddCompany"));
const Customers = lazy(() => import("./components/customer/Customers"));
const AddCustomer = lazy(() => import("./components/customer/Add/AddCustomer"));

const Users = lazy(() => import("./components/settings/user/Users"));
const AddUser = lazy(() => import("./components/settings/user/Add/AddUser"));
const Premission = lazy(() => import("./components/settings/roles/Roles"));
const EditPremission = lazy(
  () => import("./components/settings/roles/edit/EditPremission")
);
const EditLead = lazy(() => import("./components/lead/Edit/EditLead"));
const Default = lazy(() => import("./components/settings/default/Default"));
const Lead = lazy(() => import("./components/lead/Lead"));

const Inbox = lazy(() => import("./components/inbox/Inbox"));
const Dashboard = lazy(() => import("./components/dashboard/Dashboard"));
const CompletedDeal = lazy(
  () => import("./components/lead/Tabs/deal/completed-deal/CompletedDeal")
);
const Activity = lazy(() => import("./components/activity/Activity"));
const Login = lazy(() => import("./components/login/Login"));
const SettingLayout = lazy(
  () => import("./components/settings/layout/SettingsLayout")
);

const router = createBrowserRouter([
  {
    element: <Layout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      { path: "/properties", element: <Properties /> },
      { path: "/properties/add", element: <AddProperty /> },
      { path: "/properties/:id", element: <AddProperty /> },
      { path: "/properties/", element: <Properties /> },
      { path: "/properties/my_properties", element: <Properties /> },
      { path: "/properties/for_sale", element: <Properties /> },
      { path: "/properties/for_rent", element: <Properties /> },
      { path: "/properties/sold", element: <Properties /> },
      { path: "/properties/rented", element: <Properties /> },
      { path: "/properties/archived", element: <Properties /> },
      {
        path: "/properties/externalproperty",
        element: <ExternalProperty />,
      },
      { path: "/enquiries", element: <Enquiries /> },
      { path: "/enquiries/add", element: <AddEnquiry /> },
      { path: "/enquiries/:id", element: <AddEnquiry /> },
      { path: "/enquiries/valid", element: <Enquiries /> },
      { path: "/enquiries/invalid", element: <Enquiries /> },
      { path: "/enquiries/nonpersonal", element: <Enquiries /> },
      { path: "/companies", element: <Company /> },
      { path: "/companies/add", element: <AddCompany /> },
      { path: "/companies/:id", element: <AddCompany /> },
      { path: "/customers", element: <Customers /> },
      { path: "/customers/individual", element: <Customers /> },
      { path: "/customers/add", element: <AddCustomer /> },
      { path: "/customers/:id", element: <AddCustomer /> },
      { path: "/leads", element: <Lead /> },
      { path: "/leads/:id", element: <EditLead /> },
      { path: "/leads/completedeal", element: <CompletedDeal /> },
      { path: "/deals", element: <Deals /> },
      { path: "/deals/add", element: <AddDeals /> },
      { path: "/deals/my_deals", element: <Deals /> },
      { path: "/deals/shared_with_me", element: <Deals /> },
      { path: "/deals/:id", element: <AddDeals /> },
      { path: "/reports", element: <Activity /> },
      { path: "/reports/useractivity", element: <Activity /> },
      { path: "/dashboard", element: <Dashboard /> },
      { path: "/inbox", element: <Inbox /> },
      { path: "*", element: <div></div> },
    ],
  },
  { path: "/brochure/:id", element: <BrochureDesign /> },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/settings",
    element: <SettingLayout />,
    children: [
      { path: "/settings/users", element: <Users /> },
      { path: "/settings/users/add", element: <AddUser /> },
      { path: "/settings/users/:id", element: <AddUser /> },
      { path: "/settings/roles", element: <Premission /> },
      { path: "/settings/roles/:id", element: <EditPremission /> },
      { path: "/settings/defaults", element: <Default /> },
    ],
  },
  // { path: "*", element: <div>The page not found</div> },
]);

export default router;
