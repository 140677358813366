import { createSlice } from "@reduxjs/toolkit";
import { FollowUpType } from "../validation/follow-up-type";

const initialFollowUp = {
  follow_up_reason: "",
  follow_up_date: "",
};
export interface FollowUpState {
  validationErrors: object;
  followup: FollowUpType;
}
const initialState: FollowUpState = {
  validationErrors: {},
  followup: initialFollowUp,
};
export const followupSlice = createSlice({
  name: "followup",
  initialState,
  reducers: {
    setFollowUp: (state, action) => {
      state.followup = { ...state.followup, ...action.payload };
    },
    clearFollowUp: (state) => {
      state.followup = initialFollowUp;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
  },
});
export const {
  setFollowUp,
  clearFollowUp,
  setValidationErrors,
  resetValidationErrors,
} = followupSlice.actions;
export default followupSlice.reducer;
