import { createSlice } from "@reduxjs/toolkit";
import { PropertyWizardType } from "../../validation/wizard/property-wizard";
const initialPropertyWizard = {
  data: {},
  is_vacant: true,
  bathrooms: 0,
  bedrooms: 0,
  parking: 0,
  kitchen: 0,
  property_usage: "residential" as "residential" | "commercial",
  pantry: false,
};
export interface PropertyWizardState {
  validationErrors: Partial<PropertyWizardType>;
  propertywizard: Partial<PropertyWizardType>;
}

const initialState: PropertyWizardState = {
  validationErrors: {},
  propertywizard: initialPropertyWizard,
};
export const propertywizardSlice = createSlice({
  name: "propertywizard",
  initialState,
  reducers: {
    setPropertyWizard: (state, action) => {
      const newState = { ...state.propertywizard, ...action.payload };
      state.propertywizard = newState;
    },
    clearPropertyWizard: (state) => {
      state.propertywizard = initialPropertyWizard;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
  },
});
export const {
  setPropertyWizard,
  setValidationErrors,
  clearPropertyWizard,
  resetValidationErrors,
} = propertywizardSlice.actions;
export default propertywizardSlice.reducer;
