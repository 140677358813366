import { createSlice } from "@reduxjs/toolkit";
import { CompanyType } from "../validation/CompanyType";
import { FileObject } from "../types/fileType";

const initialCompany = {
  currentPage: 1,
  company_name: "",
  email: "",
  phone_number: "",
  nationality: null,
  owners: [],
  is_single_ownership: true,
  phone_dial_id: 784,
  search_owner: "",
  trade_license_number: "",
  trade_license_expiry: "",
  vat_number: "",
  contact_email: "",
  contact_name: "",
  contact_phone_number: "",
  address: "",
  po_box: null,
  city: "",
  country_of_residence: "",
};

export interface CompanyState {
  currentPage: number;
  // sort: {
  //   field: string;
  //   order: string;
  // };
  filter: {
    search: string;
    start_date: null;
    end_date: null;
  };
  validationErrors: Partial<CompanyType>;
  company: Partial<CompanyType> & {
    trade_license_file?: FileObject;
    vat_certificate?: FileObject;
    search_owner: string;
    createdFullName?: string;
    owners: { value: string; title: string }[];
  };
}
const initialFilter = {
  search: "",
  start_date: null,
  end_date: null,
};
const initialState: CompanyState = {
  currentPage: 1,
  filter: initialFilter,
  validationErrors: {},
  company: initialCompany,
};
export const companySlice = createSlice({
  name: "company",
  initialState,
  reducers: {
    // setSort: (state, action) => {
    //   const { field, order } = action.payload;
    //   state.sort = { field, order };
    // },
    setSearch: (state, action) => {
      state.filter.search = action.payload;
      state.currentPage = 1;
    },
    setStartDate: (state, action) => {
      state.filter.start_date = action.payload;
      state.currentPage = 1;
    },
    setEndDate: (state, action) => {
      state.filter.end_date = action.payload;
      state.currentPage = 1;
    },
    setCompany: (state, action) => {
      if (action.payload.is_single_ownership !== undefined) {
        state.company.owners = [];
        state.company.signatory_owner_id = undefined;
      }
      if (action.payload.owners !== undefined) {
        if (state.company.is_single_ownership) {
          state.company.owner_ids = [action.payload.owners.value];
        } else {
          state.company.owner_ids = action.payload.owners.map(
            (item: { value: string }) => item.value
          );
        }
        state.company.signatory_owner_id = undefined;
      }

      state.company = { ...state.company, ...action.payload };
    },
    clearCompany: (state) => {
      state.company = initialCompany;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
    resetFilter: (state) => {
      state.filter = initialFilter;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
  },
});
export const {
  setCompany,
  clearCompany,
  setValidationErrors,
  resetValidationErrors,
  // setSort,
  setSearch,
  setEndDate,
  setStartDate,
  resetFilter,
  setCurrentPage,
} = companySlice.actions;
export default companySlice.reducer;
