import { createSlice } from "@reduxjs/toolkit";
import { viewType } from "../validation/viewType";

const initialView = {
  enqViewType: "Excel",
  customerViewType: "Card",
  companyViewType: "Card",
  leadViewType: "Excel",
  dealViewType: "Excel",
  userViewType: "Excel",
  propertyViewType: "Card",
};
export interface ViewState {
  view: viewType;
}
const initialState: ViewState = {
  view: initialView,
};
export const viewSlice = createSlice({
  name: "view",
  initialState,
  reducers: {
    setView: (state, action) => {
      state.view = { ...state.view, ...action.payload };
    },
  },
});
export const { setView } = viewSlice.actions;
export default viewSlice.reducer;
