import { createSlice } from "@reduxjs/toolkit";
import { LoginType } from "../validation/LoginType";
import axios from "axios";

const token = JSON.parse(localStorage.getItem("token") || "null");
const permissions = JSON.parse(localStorage.getItem("permissions") || "[]");
const profile = JSON.parse(localStorage.getItem("profile") || "null");
axios.defaults.headers.common["Authorization"] = `bearer ${token}`;

const initialLogin = {
  email: "",
  password: "",
};

type Permission = {
  id: string;
  action: string;
  subject: string;
};
type Profile = {
  name: string;
  surname: string;
  profile_picture: { path: string };
  id: string;
  email: string;
};
export interface LoginState {
  validationErrors: object;
  login: LoginType;
  token: string;
  permissions: Permission[];
  profile: Profile;
}
const initialState: LoginState = {
  validationErrors: {},
  login: initialLogin,
  token: token,
  permissions: permissions,
  profile: profile,
};
export const loginSlice = createSlice({
  name: "login",
  initialState,
  reducers: {
    setLogin: (state, action) => {
      state.login = { ...state.login, ...action.payload };
    },
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setProfile: (state, action) => {
      const { name, surname, profile_picture, id, email } = action.payload;
      state.profile = { name, surname, profile_picture, id, email };
      state.permissions = action.payload.permissions;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
    clearLogin: (state) => {
      state.validationErrors = {};
      state.login = initialLogin;
      state.token = "";
      state.permissions = [];

      // Clear localStorage
      localStorage.removeItem("token");
      localStorage.removeItem("permissions");
      localStorage.removeItem("profile");

      // Clear axios default header
      delete axios.defaults.headers.common["Authorization"];
    },
  },
});
export const {
  setLogin,
  setValidationErrors,
  resetValidationErrors,
  setToken,
  setProfile,
  clearLogin,
} = loginSlice.actions;
export default loginSlice.reducer;
