import axios from "axios";

export async function getNotification(qParams = "") {
  const res = await axios.get(`notifications${qParams}`);

  return res.data;
}
export async function updateNotification(selectedRows?: string[]) {
  return axios.put(
    `notifications`,
    { notification_Ids: selectedRows },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
export async function updateNotificationdelete(selectedRows?: string[]) {
  return axios.put(
    `notifications`,
    { notification_Ids: selectedRows },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
export async function removeNotification(id?: string): Promise<void> {
  return await axios.delete(`notifications/${id}`);
}
export async function removeAllNotification(selectedRows?: string[]) {
  return axios.put(
    `notifications/clearall`,
    { notification_Ids: selectedRows },
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
}
