import Icon from "./Icon";
export default function AddIcon({
  size,
  white,
}: {
  size: number;
  white: boolean;
}) {
  return (
    <Icon
      width={size}
      height={size}
      iconUrl={`/icons/Add_${white ? "white" : "blue"}.svg`}
    />
  );
}
