import { createSlice } from "@reduxjs/toolkit";
import { ExternalPropertyType } from "../validation/external-property-type";

const initialExternalProperty = {
  is_vacant: true,
};
export interface ExternalPropertyState {
  filter: {
    search: string;
  };
  validationErrors: object;
  externalproperty: Partial<ExternalPropertyType>;
}
const initialState: ExternalPropertyState = {
  filter: {
    search: "",
  },
  validationErrors: {},
  externalproperty: initialExternalProperty,
};
export const externalpropertySlice = createSlice({
  name: "externalproperty",
  initialState,
  reducers: {
    // setSort: (state, action) => {
    //   const { field, order } = action.payload;
    //   state.sort = { field, order };
    // },
    setSearch: (state, action) => {
      state.filter.search = action.payload;
    },
    setExternalProperty: (state, action) => {
      state.externalproperty = { ...state.externalproperty, ...action.payload };
    },
    clearExternalProperty: (state) => {
      state.externalproperty = initialExternalProperty;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
  },
});
export const {
  setExternalProperty,
  clearExternalProperty,
  setValidationErrors,
  resetValidationErrors,
  //   setSort,
  setSearch,
} = externalpropertySlice.actions;
export default externalpropertySlice.reducer;
