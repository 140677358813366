import { createSlice } from "@reduxjs/toolkit";
// import { AttachmentType } from "../validation/attachment-type";

const initialAttachment = {
  attach_files: [],
  attachment_file_ids: [],
  search: "",
};
export interface IAttachFiles {
attachFileId: string;
  createdAt: string;
  createdBy?: null | string; 
  dealAttachmentId?: null | string; 
  dealVisaShareholderId?: null | string; 
  deletedAt?: null | Date;
  filename: string;
  id: string;
  mimetype: string;
  path: string;
  propertyId?: null | string; 
  size: number;
  updatedAt?: null | Date;
  watermarked: boolean;
  userFullName : string
}
export interface AttachmentState {
  filter: {
    search: string;
  };
  validationErrors: object;
  attachment: {
    attach_files:IAttachFiles[],
  attachment_file_ids: string[],
  search: string,
  };
}
const initialFilter = {
  search: "",
};
const initialState: AttachmentState = {
  filter: initialFilter,
  validationErrors: {},
  attachment: initialAttachment,
};
export const attachmentSlice = createSlice({
  name: "attachment",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.filter.search = action.payload;
    },
    setAttachment: (state, action) => {
      state.attachment = { ...state.attachment, ...action.payload };
      if (action.payload.deal_attachments) {
        state.attachment.attachment_file_ids =
          action.payload.attachment_file_ids.map(
            (item: { file_id: string; id: string }) => item.file_id || item.id
          );
      }
    },
    clearAttachment: (state) => {
      state.attachment = initialAttachment;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
    resetFilter: (state) => {
      state.filter = initialFilter;
    },
  },
});
export const {
  setSearch,
  setAttachment,
  clearAttachment,
  setValidationErrors,
  resetValidationErrors,
  resetFilter,
} = attachmentSlice.actions;
export default attachmentSlice.reducer;
