import { createSlice } from "@reduxjs/toolkit";
import { SourceType } from "../validation/sourceType";

const initialSource = {
  title: "",
};
export interface SourceState {
  validationErrors: object;
  source: SourceType;
}
const initialState: SourceState = {
  validationErrors: {},
  source: initialSource,
};
export const sourceSlice = createSlice({
  name: "source",
  initialState,
  reducers: {
    setSource: (state, action) => {
      state.source = { ...state.source, ...action.payload };
    },
    clearSource: (state) => {
      state.source = initialSource;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
  },
});
export const {
  setSource,
  clearSource,
  setValidationErrors,
  resetValidationErrors,
} = sourceSlice.actions;
export default sourceSlice.reducer;
