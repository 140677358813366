import { createSlice } from "@reduxjs/toolkit";
// import { RentAndSaleType } from "../validation/rent-sale-card";

const initialRentAndSaleCard = {};
export interface RentAndSaleCardState {
  filter: {
    start_date: null;
    end_date: null;
    compare_start_date: null;
    compare_end_date: null;
  };
  validationErrors: object;
  //   rentandsalecard: RentAndSaleType;
  rentandsalecard: object;
}
const initialFilter = {
  start_date: null,
  end_date: null,
  compare_start_date: null,
  compare_end_date: null,
};
const initialState: RentAndSaleCardState = {
  filter: initialFilter,
  validationErrors: {},
  rentandsalecard: initialRentAndSaleCard,
};
export const rentandsalecardSlice = createSlice({
  name: " rentandsalecard",
  initialState,
  reducers: {
    setStartDate: (state, action) => {
      state.filter.start_date = action.payload;
    },
    setEndDate: (state, action) => {
      state.filter.end_date = action.payload;
    },
    setComperStartDate: (state, action) => {
      state.filter.compare_start_date = action.payload;
    },
    setComperEndDate: (state, action) => {
      state.filter.compare_end_date = action.payload;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
    resetFilter: (state) => {
      state.filter = initialFilter;
    },
  },
});
export const {
  setValidationErrors,
  resetValidationErrors,
  setStartDate,
  setEndDate,
  setComperStartDate,
  setComperEndDate,
  resetFilter,
} = rentandsalecardSlice.actions;
export default rentandsalecardSlice.reducer;
