import { useQuery } from "@tanstack/react-query";

import { getNotification } from "../../api/notification";
import CardCountUndread from "./CardCountUndread";

const ListCounterUnRead = () => {
  const params = "?" + "seen=false";
  const queries: string[] = [];
  const { data } = useQuery({
    queryKey: ["unread", queries],
    queryFn: async function () {
      const result = await getNotification(params);
      return result;
    },
  });
  return (
    <>
      {data?.data?.map((item: { id: string }, index: number) => (
        <CardCountUndread key={item.id} i={index} />
      ))}
    </>
  );
};

export default ListCounterUnRead;
