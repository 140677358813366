import { createSlice } from "@reduxjs/toolkit";
import { LeadType } from "../validation/LeadType";

const initialLead = {
  tab: 0,
};
export interface LeadState {
  currentPage: number;
  // sort: {
  //   field: string;
  //   order: string;
  // };
  filter: {
    search: string;
    requestFor: { value: string; title: string };
  };
  validationErrors: object;
  lead: LeadType & { tab?: number };
}
const initialFilter = {
  search: "",
  requestFor: { value: "", title: "" },
};
const initialState: LeadState = {
  currentPage: 1,
  // sort: {
  //   field: "",
  //   order: "",
  // },
  filter: initialFilter,
  validationErrors: {},
  lead: initialLead,
};
export const leadSlice = createSlice({
  name: "lead",
  initialState,
  reducers: {
    // setSort: (state, action) => {
    //   const { field, order } = action.payload;
    //   state.sort = { field, order };
    // },
    setSearch: (state, action) => {
      state.filter.search = action.payload;
      state.currentPage = 1;
    },
    setRequestFor: (state, action) => {
      state.filter.requestFor = action.payload;
      state.currentPage = 1;
    },
    setLead: (state, action) => {
      state.lead = { ...state.lead, ...action.payload };
    },
    clearLead: (state) => {
      state.lead = initialLead;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
    resetFilter: (state) => {
      state.filter = initialFilter;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
  },
});
export const {
  setLead,
  clearLead,
  setValidationErrors,
  resetValidationErrors,
  // setSort,
  setSearch,
  setRequestFor,
  resetFilter,
  setCurrentPage,
} = leadSlice.actions;
export default leadSlice.reducer;
