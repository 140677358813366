import { createSlice } from "@reduxjs/toolkit";
import { FeatureType } from "../validation/featureType";

const initialFeature = {
  title: "",
};
export interface FeatureState {
  validationErrors: object;
  feature: FeatureType;
}
const initialState: FeatureState = {
  validationErrors: {},
  feature: initialFeature,
};
export const featureSlice = createSlice({
  name: "feature",
  initialState,
  reducers: {
    setFeature: (state, action) => {
      state.feature = { ...state.feature, ...action.payload };
    },
    clearFeature: (state) => {
      state.feature = initialFeature;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
  },
});
export const {
  setFeature,
  clearFeature,
  setValidationErrors,
  resetValidationErrors,
} = featureSlice.actions;
export default featureSlice.reducer;
