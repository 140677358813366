import { useDispatch, useSelector } from "react-redux";
import { getUrl } from "../../utils/fileUrl";
import { RootState } from "../../app/store";
import { useQueryClient } from "@tanstack/react-query";
import { clearLogin, setToken } from "../../store/loginSlice";
import Icon from "./Icon";
import { clearDeal } from "../../store/deal-slice";
import { clearSiteVisit } from "../../store/site-visit-slice";

const Profile = () => {
  const login = useSelector((state: RootState) => state.login);
  const profile = login.profile;
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  return (
    <div className="relative flex gap-2 justify-between items-center mr-2 group cursor-pointer pb-2 mt-2">
      <div className=" flex flex-1 items-center">
        <div className="w-10 h-10 rounded-full flex items-center justify-center p-0.5 bg-dark-blue/20 group-hover:bg-dark-blue transition-all">
          {/* {profile?.profile_picture && ( */}
          {profile?.profile_picture != null ? (
            <img
              className="w-full h-full rounded-full object-cover"
              src={getUrl(profile?.profile_picture?.path)}
              alt=""
            />
          ) : (
            <span className="flex justify-center items-center font-bold w-full h-full rounded-full capitalize border text-white bg-[#102448]">
              {profile?.name.substring(0, 1)} {profile?.surname.substring(0, 1)}
            </span>
          )}
        </div>
      </div>
      <div className="hidden group-hover:flex absolute z-[1000] shadow-lg min-w-60 h-fit top-10 right-0 mt-2 rounded-lg bg-white border border-dark-blue/10 p-2 flex-col items-center">
        <div className="w-20 h-20 rounded-full flex items-center justify-center shadow-xl my-3">
          {/* {profile?.profile_picture && ( */}
          {profile?.profile_picture != null ? (
            <img
              className="w-full h-full rounded-full object-cover"
              src={getUrl(profile?.profile_picture?.path)}
              alt=""
            />
          ) : (
            <span className="flex justify-center items-center font-bold w-full h-full rounded-full capitalize border text-white bg-[#102448] text-xl">
              {profile?.name.substring(0, 1)} {profile?.surname.substring(0, 1)}
            </span>
          )}
        </div>
        <div className="mt-2 flex items-center capitalize text-[#102448] text-[16px] font-semibold cursor-default">
          {profile?.name} {profile?.surname}
        </div>
        <div className="flex items-center text-[#102448] text-sm cursor-default">
          {profile?.email}
        </div>
        <div className="flex justify-center w-full font-semibold">
          <button
            onClick={async () => {
              localStorage.removeItem("token");
              await queryClient.removeQueries();
              dispatch(setToken(null));
              dispatch(clearDeal());
              dispatch(clearSiteVisit());
              dispatch(clearLogin());
            }}
            className="transition-all mt-5 w-full bg-dark-blue/10 hover:bg-dark-blue/30 text-dark-blue rounded-md py-2 flex items-center justify-center gap-2"
          >
            <Icon
              height={20}
              width={20}
              iconUrl="/icons/Logout.svg"
              customeClass="-ml-2"
            />
            Logout
          </button>
        </div>
      </div>
    </div>
  );
};

export default Profile;
