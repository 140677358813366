import { createSlice } from "@reduxjs/toolkit";
import { PropertyType } from "../../validation/PropertyTypes";
import { FileObject, FileType } from "../../types/fileType";

const initialProperty = {
  currentPage: 1,
  city: "",
  property_title: "",
  property_type: { title: "" }, // Change this line
  property_description: "",
  kitchen: 0,
  parking: 0,
  bathrooms: 0,
  bedrooms: 0,
  area_id: "",
  is_vacant: true,
  is_exclusive_property: false,
  is_off_plan: true,
  is_morgaged: false,
  is_free_hold: false,
  is_prima_management: false,
  is_for_sale: false,
  is_for_rent: false,
  property_usage: "residential" as "residential" | "commercial",
  featur_Ids: [],
  photo_ids: [],
  property_brochure: [],
  commerial_structure: null,
  search_owner: "",
  search_company_owner: "",
  isPropertyEditale: true,
  from_price: "",
  pantry: false,
  show_map_in_brochure: false,
  archive_reason: "",
  archive_comment: "",
  property_agent: "",
};
export interface PropertiesState {
  currentPage: number;
  validationErrors: Partial<PropertyType> & { photo_ids?: string };

  property: Partial<PropertyType> & {
    createdAt?: string;
    updatedAt?: string;
    createdFullName?: string;
    title_deed_file?: FileObject;
    floor_plan?: FileObject;
    brochure?: FileObject;
    search_owner?: string;
    search_company_owner?: string;
    isPropertyEditale?: boolean;
  };
  sort: {
    field: string;
    order: string;
  };
  filter: {
    search: string;
    listingType: { id: string; title: string };
    status: { id: string; title: string };
    community: { id: string; title: string }[];
    PropId: string;
    bedrooms: { id: string; title: string };
    from_price: number | string;
    to_price: number | string;
    from_size: number | string;
    to_size: number | string;
    creator: { id: string; title: string };
    property_agent: { id: string; title: string };
  };
}
const initialFilter = {
  search: "",
  listingType: { id: "", title: "" },
  status: { id: "", title: "" },
  community: [],
  PropId: "",
  bedrooms: { id: "", title: "" },
  from_price: "",
  to_price: "",
  from_size: "",
  to_size: "",
  creator: { id: "", title: "" },
  property_agent: { id: "", title: "" },
};
const initialState: PropertiesState = {
  currentPage: 1,
  sort: {
    field: "",
    order: "",
  },
  filter: initialFilter,
  validationErrors: {},
  property: initialProperty,
};

export const propertiesSlice = createSlice({
  name: "properties",
  initialState,
  reducers: {
    setSort: (state, action) => {
      const { field, order } = action.payload;
      state.sort = { field, order };
    },
    setSearch: (state, action) => {
      state.filter.search = action.payload;
      state.currentPage = 1;
    },
    setFromPrice: (state, action) => {
      state.filter.from_price = action.payload;
      state.currentPage = 1;
    },
    setToPrice: (state, action) => {
      state.filter.to_price = action.payload;
      state.currentPage = 1;
    },
    setFromSize: (state, action) => {
      state.filter.from_size = action.payload;
      state.currentPage = 1;
    },
    setToSize: (state, action) => {
      state.filter.to_size = action.payload;
      state.currentPage = 1;
    },
    setBedroom: (state, action) => {
      state.filter.bedrooms = action.payload;
      state.currentPage = 1;
    },
    setStatus: (state, action) => {
      state.filter.status = action.payload;
      state.currentPage = 1;
    },
    setListingType: (state, action) => {
      state.filter.listingType = action.payload;
      state.currentPage = 1;
    },
    setCommunity: (state, action) => {
      state.filter.community = action.payload;
      state.currentPage = 1;
    },
    setCreator: (state, action) => {
      state.filter.property_agent = action.payload;
      state.currentPage = 1;
    },
    setPropId: (state, action) => {
      state.filter.PropId = action.payload;
    },
    setProperty: (state, action) => {
      state.property = { ...state.property, ...action.payload };
    },
    clearProperty: (state) => {
      state.property = initialProperty;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
    resetFilter: (state) => {
      state.filter = initialFilter;
    },
    setCurrentPage: (state, { payload }) => {
      state.currentPage = payload;
    },
    removeFile: (state, action) => {
      state.property.photo_ids = state.property?.photo_ids?.filter(
        (id) => id !== action.payload
      );
      state.property.photos = state.property.photos.filter(
        (photo: FileType) => photo.id !== action.payload
      );
    },
    setIs_cover: (state, action) => {
      const { id } = action.payload;
      state.property.photos = state.property.photos.map((photo: FileType) => {
        if (photo.id === id) {
          return { ...photo, is_cover: true };
        } else {
          return { ...photo, is_cover: false };
        }
      });
    },
    resetProperty: (state) => {
      state.property = initialProperty;
    },
  },
});

export const {
  setSort,
  setSearch,
  setFromPrice,
  setToPrice,
  setFromSize,
  setToSize,
  setBedroom,
  setStatus,
  setListingType,
  setCommunity,
  setCreator,
  setPropId,
  setProperty,
  clearProperty,
  setValidationErrors,
  resetValidationErrors,
  resetFilter,
  setCurrentPage,
  removeFile,
  setIs_cover,
} = propertiesSlice.actions;

export default propertiesSlice.reducer;
