import ReactDOM from "react-dom/client";
import "./css/index.css";
import { RouterProvider } from "react-router-dom";
import router from "./router";
import { store } from "./app/store";
import { Provider } from "react-redux";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import axios from "axios";
import { showErrorMessage } from "./helpers/alert";
import { Suspense } from "react";
import { Toaster } from "react-hot-toast";

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      console.log("getting unauthorized");
      localStorage.removeItem("token");
      location.href = "/login";
    }
    let message = error.message;
    if (error.response?.data?.errorDetails?.message) {
      message = error.response?.data?.errorDetails?.message;
    }
    showErrorMessage(message);
    throw error;
  }
);

const client = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: Infinity,
    },
  },
});
axios.defaults.baseURL = import.meta.env.VITE_API_URL;

ReactDOM.createRoot(document.getElementById("root")!).render(
  <QueryClientProvider client={client}>
    <ReactQueryDevtools initialIsOpen={false} />
    <Suspense fallback={<div></div>}>
      <Provider store={store}>
        <>
          <RouterProvider router={router} />
          <Toaster gutter={1} position="bottom-left" />
        </>
      </Provider>
    </Suspense>
  </QueryClientProvider>
);
