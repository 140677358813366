import { createSlice } from "@reduxjs/toolkit";

const initialNotification = {
  tab: 0,
  currentPage: 1,
  currentPageUnread: 1,
  currentPageReminder: 1,
  currentPageAssigne: 1,
};
export interface NotificationState {
  currentPage: number;
  currentPageUnread: number;
  currentPageReminder: number;
  currentPageAssigne: number;
  validationErrors: object;
  selectedRows: string[];
  notification: object;
}

const initialState: NotificationState = {
  currentPage: 1,
  currentPageUnread: 1,
  currentPageReminder: 1,
  currentPageAssigne: 1,
  validationErrors: {},
  selectedRows: [],
  notification: {},
};
export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    setNotification: (state, action) => {
      state.notification = { ...state.notification, ...action.payload };
    },
    clearNotification: (state) => {
      state.notification = initialNotification;
    },

    toggleSelectedRows: (state, action) => {
      const index = state.selectedRows.indexOf(action.payload);
      if (index !== -1) {
        state.selectedRows.splice(index, 1);
      } else {
        state.selectedRows.push(action.payload);
      }
    },
    toggleSelectedAll: (state, action) => {
      let allSelected = true;
      for (const row of action.payload.allRows) {
        if (!state.selectedRows.includes(row)) {
          allSelected = false;
        }
      }
      if (allSelected) {
        state.selectedRows = [];
      } else {
        const selectedRows = new Set([
          ...state.selectedRows,
          ...action.payload.allRows,
        ]);
        state.selectedRows = Array.from(selectedRows);
      }
    },
    clearSelectedRows: (state) => {
      state.selectedRows = [];
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
    setCurrentPage(state, { payload }) {
      state.currentPage = payload;
    },
    setCurrentPageUnread(state, { payload }) {
      state.currentPageUnread = payload;
    },
    setCurrentPageReminder(state, { payload }) {
      state.currentPageReminder = payload;
    },
    setCurrentPageAssigne(state, { payload }) {
      state.currentPageAssigne = payload;
    },
  },
});
export const {
  setNotification,
  clearNotification,
  setValidationErrors,
  resetValidationErrors,
  toggleSelectedRows,
  toggleSelectedAll,
  clearSelectedRows,
  setCurrentPage,
  setCurrentPageUnread,
  setCurrentPageReminder,
  setCurrentPageAssigne,
} = notificationSlice.actions;
export default notificationSlice.reducer;
