import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { hasPermission } from "../utils/hasPermission";
import { RootState } from "../app/store";

const Home = () => {
  const permissions = useSelector(
    (state: RootState) => state.login.permissions
  );

  return (
    <div>
      {hasPermission(permissions, "Read", "property") && (
        <Navigate to="/properties" />
      )}
    </div>
  );
};

export default Home;
