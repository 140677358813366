import { createSlice } from "@reduxjs/toolkit";
import { PropertyViewType } from "../validation/property-view-type";

const initialPropertyView = {
  title: "",
};
export interface PropertyViewState {
  validationErrors: object;
  propertyview: PropertyViewType;
}
const initialState: PropertyViewState = {
  validationErrors: {},
  propertyview: initialPropertyView,
};
export const propertyviewSlice = createSlice({
  name: "propertyview",
  initialState,
  reducers: {
    setPropertyView: (state, action) => {
      state.propertyview = { ...state.propertyview, ...action.payload };
    },
    clearPropertyView: (state) => {
      state.propertyview = initialPropertyView;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
  },
});
export const {
  setPropertyView,
  clearPropertyView,
  setValidationErrors,
  resetValidationErrors,
} = propertyviewSlice.actions;
export default propertyviewSlice.reducer;
