import { createSlice } from "@reduxjs/toolkit";

export interface StepState {
  currentStep: number;
  lastStep: number;
}

const initialState: StepState = {
  currentStep: 1,
  lastStep: 5,
};

export const stepSlice = createSlice({
  name: "step",
  initialState,
  reducers: {
    setStep: (state, action) => {
      state.currentStep = action.payload;
    },
    nextStep: (state) => {
      if (state.currentStep < state.lastStep) {
        state.currentStep += 1;
      }
    },
    prevStep: (state) => {
      if (state.currentStep > 1) {
        state.currentStep -= 1;
      }
    },
    resetStep: (state) => {
      state.currentStep = 1;
    },
    setLastStep: (state, action) => {
      state.lastStep = action.payload;
    },
  },
});

export const { setStep, nextStep, prevStep, resetStep, setLastStep } =
  stepSlice.actions;
export default stepSlice.reducer;
