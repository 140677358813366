import { createSlice } from "@reduxjs/toolkit";
import { UserActivityType } from "../validation/user-activity-type";

const initialUserActivity = {};
export interface UserActivityState {
  filter: {
    search: string;
    start_date: null;
    end_date: null;
  };
  validationErrors: object;
  useractivity: UserActivityType;
}
const initialFilter = {
  search: "",
  start_date: null,
  end_date: null,
};
const initialState: UserActivityState = {
  filter: initialFilter,
  validationErrors: {},
  useractivity: initialUserActivity,
};
export const useractivitySlice = createSlice({
  name: "useractivity",
  initialState,
  reducers: {
    setSearch: (state, action) => {
      state.filter.search = action.payload;
    },
    setStartDate: (state, action) => {
      state.filter.start_date = action.payload;
    },
    setEndDate: (state, action) => {
      state.filter.end_date = action.payload;
    },
    setUserActivity: (state, action) => {
      state.useractivity = { ...state.useractivity, ...action.payload };
    },
    clearUserActivity: (state) => {
      state.useractivity = initialUserActivity;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
    resetFilter: (state) => {
      state.filter = initialFilter;
    },
  },
});
export const {
  setUserActivity,
  clearUserActivity,
  setValidationErrors,
  resetValidationErrors,
  //   setSort,
  setSearch,
  setEndDate,
  setStartDate,
  resetFilter,
} = useractivitySlice.actions;
export default useractivitySlice.reducer;
