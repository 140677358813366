import { createSlice } from "@reduxjs/toolkit";
import { LocationWizardType } from "../../validation/wizard/location";

const initialLocationWizard = {
  city: "",
  property_title: "",
  area_id: "",
  building_number: "",
};
export interface LocationWizardState {
  validationErrors: Partial<LocationWizardType>;
  locationwizard: Partial<LocationWizardType>;
}

const initialState: LocationWizardState = {
  validationErrors: {},
  locationwizard: initialLocationWizard,
};
export const locationwizardSlice = createSlice({
  name: "locationwizard",
  initialState,
  reducers: {
    setLocationWizard: (state, action) => {
      const newState = { ...state.locationwizard, ...action.payload };
      state.locationwizard = newState;
    },
    clearLocationWizard: (state) => {
      state.locationwizard = initialLocationWizard;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
  },
});
export const {
  setLocationWizard,
  setValidationErrors,
  clearLocationWizard,
  resetValidationErrors,
} = locationwizardSlice.actions;
export default locationwizardSlice.reducer;
