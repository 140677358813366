import { createSlice } from "@reduxjs/toolkit";
import { PreOwnerWizardType } from "../../validation/wizard/pre-owner";

const initialPreOwnerWizard = {
  is_company_owner: true,
  newAdded: false,
};
export interface PreOwnerWizardState {
  validationErrors: Partial<PreOwnerWizardType>;
  preownerwizard: Partial<PreOwnerWizardType>;
}

const initialState: PreOwnerWizardState = {
  validationErrors: {},
  preownerwizard: initialPreOwnerWizard,
};
export const preownerwizardSlice = createSlice({
  name: "preownerwizard",
  initialState,
  reducers: {
    setPreOwnerWizard: (state, action) => {
      const newState = { ...state.preownerwizard, ...action.payload };
      state.preownerwizard = newState;
    },
    clearPreOwnerWizard: (state) => {
      state.preownerwizard = initialPreOwnerWizard;
    },
    setValidationErrors: (state, action) => {
      state.validationErrors = { ...action.payload };
    },
    resetValidationErrors: (state) => {
      state.validationErrors = {};
    },
  },
});
export const {
  setPreOwnerWizard,
  setValidationErrors,
  clearPreOwnerWizard,
  resetValidationErrors,
} = preownerwizardSlice.actions;
export default preownerwizardSlice.reducer;
